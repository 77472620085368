import OcrTipSection1 from './ocr-tip-section-1.png';
import OcrTipSection2 from './ocr-tip-section-2.png';
import OcrTipSection3 from './ocr-tip-section-3.png';
import OcrTipSection4 from './ocr-tip-section-4.png';
import TicketScan from './ticket-scan.svg';
import UserRegistrationSuccess from './user-registration-success.svg';

export const images = {
  OcrTipSection1,
  OcrTipSection2,
  OcrTipSection3,
  OcrTipSection4,
};

export { TicketScan, UserRegistrationSuccess };
